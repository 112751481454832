<template>
  <div class="ebook-create-page">
    <h2>Create Ebook</h2>

    <ebook-form
      v-model="form"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import EbookForm from '../components/EbookForm.vue'

export default {
  name: 'CampaignCreate',

  components: {
    EbookForm
  },

  data () {
    return {
      form: null
    }
  },

  methods: {
    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const resp = await this.$ebooks.create(this.form)
        const ebook = resp.data.data
        this.$router.push({
          name: 'ebook-edit',
          params: {
            id: ebook.id
          }
        })
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
