<template>
  <b-card class="ebook-form">
    <!-- Name -->
    <b-form-group
      v-for="locale in locales"
      :key="`name_${locale}`"
    >
      <label>Name</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-input
        v-model="internalValue[`name_${locale}`]"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Description -->
    <b-form-group
      v-for="locale in locales"
      :key="`description_${locale}`"
    >
      <label>Description</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-textarea
        v-model="internalValue[`description_${locale}`]"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Thumbnail -->
    <b-form-group>
      <label>Thumbnail</label>
      <file-selector
        v-model="internalValue.thumbnail"
        @input="emitInput"
      />
    </b-form-group>

    <!-- File -->
    <b-form-group>
      <label>File Download</label>
      <file-selector
        v-model="internalValue.file"
        @input="emitInput"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import FileSelector from './FileSelector.vue'

export default {
  name: 'EbookForm',

  components: {
    FileSelector
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        name_th: '',
        name_en: '',
        description_th: '',
        description_en: '',
        thumbnail: [],
        file: []
      }
    }
  },

  created () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
